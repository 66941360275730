.div-principal-pagos-vendedor{
}

.div-fechas-pagos{
  padding-top: 2%;
  padding-left: 30%;
  display:inline-flex;
  margin-bottom: 5%;
}

#FormExpiry {display: inline-flex;}
#FormExpiry.field{width: 50%;}