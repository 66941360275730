
.About .seccion0{margin-top: 16vh}
.About .header{background-image: url("../imgs/portada3.png");background-size: cover;width: 90%;margin: 0px auto;display: flex;flex-direction: column;justify-content: flex-start;align-items: center;height: 60vh;background-repeat: no-repeat;background-position: center;}
.About .circleAbout{width: 25%;position: absolute;right: -30px;top: -30px;}
.About .redesContent{display: flex;flex-direction: row;justify-content: space-around;width: 60%;margin-top: 0vh;}
.About .itemRedes{cursor: pointer;background-color: #FCA043;border-radius: 50%;width: 7vw;height: 7vw;display: flex;justify-content: center;align-items: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.About .itemRedes:hover{transform: scale(1.2);transform-origin: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.About .itemRedes.down{margin-top: 10vh}
.About .itemRedes i{color:#fff;margin: 0px;text-align:center;}
.About .itemRedes i.big.icon{font-size: 3em}
.About .tituloContent{width: 20%}
.About .titulo-seccion{color:#fff;font-size: 3rem;font-weight: 800;}
.About .seccion1{margin-top: 15vh;margin-bottom: 10vh}
.About .itemAbout{width: 70%;margin: 0px auto;margin-top: 10vh}
.About .itemTitulo{display: flex;flex-direction: row;justify-content: flex-start;align-items: center;}
.About .itemTitulo p {color:#3e3939;margin-left: 10vw;font-size: 1.7rem;font-weight: 700;position: relative;}
.About .itemTitulo p::after {content:'';background-color:#F46363;width:100%;height: 1px;position: absolute;top: 50%;transform: translateY(-50%);margin-left: 30px}
.About .circleGris{width: 30px;height: 30px;background-color: #6d6d6d;border-radius: 50%;position: absolute;}
.About .circleGris::after{content:'';width:1px;height: 20vh;background-color: #c1c1c1;position: absolute;left: 50%;transform: translateX(-50%);margin-top: 30px}
.About .itemDescripcion{margin-left: 10vw;margin-top: 20px}


.Membership .seccion1{width: 90%;margin: 0px auto;display: flex;flex-direction:row;justify-content: space-between;margin-top: 10vh}
.Membership .header{height: 100vh;width: '100%';display: flex;justify-content: flex-start;;align-items: center;}
.Membership .contentMembership{width:60%;display: flex;flex-direction: column;justify-content: center;align-items: center;margin:0px auto;}
.Membership .TituloContent{margin-left: 10vw}
.Membership .Titulo{font-size: 2rem;font-weight: 800;}
.Membership .circleHeader{position: absolute;width: 7vw;height: 7vw;bottom: 40px;left: 25%;}
.itemMembership {margin-top:30px;}
.member-item{background-color:#F5F2F2;width: 15vw;padding: 20px;width:100%;cursor:pointer;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.member-item p{font-size:1.1em;text-align: center;color:#707070;font-weight: 600;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.member-item.activo{background-color:#F46363;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.member-item.activo p{color:#fff;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}



.Faqs .seccion0 {width: 90%;margin: 0px auto;display: flex;flex-direction:column;justify-content: flex-start;margin-top: 16vh}
.FaqImgContent{    display: flex;
    margin: 0px auto;
    margin-top: -5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;}
.FaqItemMenu{z-index: 3;background-color: #fffffff2;cursor: pointer;width: 17rem;height: 17rem;display: flex;flex-direction: column;border-radius: 5px;justify-content: center;align-items: center;border: 1px solid #c1c1c153;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.FaqItemMenu.activo{z-index: 3;background-color: #fffffff2;transform: scale(1.2);transform-origin: center;-webkit-box-shadow: -3px 3px 4px 3px #00000029;box-shadow: -3px 3px 4px 3px #00000029;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}

.FaqItemMenu img{width: 40%;margin: 0px auto;}
.FaqItemMenu p{font-size: 1.6rem;font-weight: 700;text-align: center;margin-top: 20px}

.FaqItemMenuImage{width: 40%;margin-top: -25vh}
.ItemFaqContent{margin-top: 20rem;display:flex;flex-direction: column;align-items:center;justify-content: flex-start;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.itemFaqs{cursor: pointer;width: 90%;padding: 20px;padding-left: 10px;padding-right: 20px;display: flex;border-radius: 5px;flex-direction: row;margin: 10px;align-items: center;justify-content: space-between;background-color: #fff;-webkit-box-shadow: -3px 3px 4px 3px #00000029;box-shadow: -3px 3px 4px 3px #00000029;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.itemFaqs p {margin: 0px;font-size: 1.2rem;font-weight: 600;color:#F46363;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.ItemDescripcionFaq{width: 90%;padding: 10px;padding-left: 10px;padding-right: 20px;font-size: 1.1rem;color:#606060;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}

.Contact .seccion0 {width: 100%;margin: 0px auto;display: flex;flex-direction:row;justify-content: space-around;margin-top: -18rem;position:relative;z-index: 2;}
.FormContent{padding: 15px;background-color:#ffffffd6;-webkit-box-shadow: -3px 3px 4px 3px #00000029;box-shadow: -3px 3px 4px 3px #00000029}
.TituloContent{padding: 20px;padding-bottom: 5px; color:black}
.tituloContact{font-size:1.5rem;font-weight: 800;color:black}
.Form{display:flex;flex-direction: column;justify-content: center;align-items:center;}
.FormItem{width: 80%;margin-top: 30px}
.FormItem p {font-size: 0.9rem;font-weight: 600;margin: 0px; color:black}
.FormItem input{margin-top: 5px;border:none;background-color: #e1e1e1;padding: 5px}
.FormItem textarea{width: 100%;margin-top: 5px;border:none;background-color: #e1e1e1;padding: 10px;height:10vh}
.buttonSend{cursor:pointer; background-color: #F46363;width: 80%;margin-top: 20px;padding: 10px 20px;text-align: center;color:#fff;font-weight: 700;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.buttonSend:hover{transform: scale(1.04);transform-origin: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}





@media (max-width:990px){
    .About .itemTitulo p::after {width:10%;}

    .FaqItemMenu{width: 13rem;height: 13rem;}
    .FaqItemMenu p{font-size: 1.1rem;}
    }

 @media (max-width:700px) {
    .Membership .seccion1{flex-direction:column-reverse ;margin-top:2rem}
     
 }   
@media (max-width: 575.98px) { 
    .FaqItemMenu{width: 9rem;height: 9rem;}

    .FaqItemMenu p{font-size: .8rem;}

 }

