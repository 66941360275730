
.IconoRight{margin-right: 0;margin-left: auto;}
#HeaderMoneySafe{ margin: 20px 0;}
.div-principal-pagos-vendedor{}
span.MuiTypography-subheading-21 {color: #fff;}

.div-filtro-fechas-pagos{padding-top: 2%;padding-left: 30%;display:inline-flex;margin-bottom: 5%;}
.div-filtro-nombres-pagos{padding-left: 40%;}
.div-tab-pagos-en-proceso{height:100%;width:100%;display: flex;position:absolute;}
.div-card-pagos-vendedor{width:64%;overflow: scroll;}
.div-tab-pagos-terminados{height:100%;width:100%;display: flex;position:fixed;}
.div-card-pagos-terminados-vendedor{width:64%;overflow: scroll;}
.div-card-ventas-mes-vendedor{float: right;margin-right: 3%;}

.div-principal-card-pagos-vendedor{cursor:pointer;}

.contentBuscador{position: relative;margin: 0px auto;width: 40%;margin-top: 20px; bottom: 10px}
.contentBuscador .Buscador{display: flex;}
.contentBuscador .Buscador input{height: 30px;margin: 0px 10px;background-color: #fff;font-size: 15px;font-family: 'Roboto';border-radius: 5px;padding: 5px;color: #6f6f6f;font-weight: 500}
.btnBuscarFecha {cursor: pointer;margin:0px 15px;background-color: #e69b2b;width: 250px;color: #fff;text-align: center;font-weight: 600;padding: 5px;border-radius: 5px;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.btnBuscarFecha:hover{background-color: #f9c981;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.buscadorPorID{margin-top: 30px;}
.buscadorPorID input{height: 30px;margin: 0px auto;background-color: #fff;font-size: 15px;font-family: 'Roboto';border-radius: 5px;padding: 5px; width: 100%;color: #6f6f6f;font-weight: 500}
