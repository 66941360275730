.carouselContent{	position: relative; z-index:3;width: 90vw;margin: 0px auto;margin-top: 10vh;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.carousel{ display: flex;flex-direction: row;justify-content: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}

.tituloItem{width:33vw;position: absolute;top:50%;left: 30px;transform: translate(0,-50%);-webkit-transition: all .9s ease; -moz-transition: all .9s ease; -o-transition: all .9s ease; transition: all .9s ease;}
.tituloItem.oculto{width:33vw;opacity: 0;left: 30px;position: absolute;top:50%;transform: translate(-30px,-50%);  -webkit-transition: all .3s ease; -moz-transition: all .3s ease; -o-transition: all .3s ease; transition: all .3s ease;}

.square.small .carouselImage {transform: translateX(-4vw); }
.square{position: relative;box-shadow: -2px 3px 7px 0px #0000001f;border-radius: 10px;height: 35vh ;display:flex;flex-direction:row;padding: 45px 10px;margin-left: 30px;justify-content: flex-end;align-items: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.square.big{width: 55vw;}
.square.small{width: 20vw;}
.carouselImage{width:7vw;object-fit: contain;margin-right:0px;margin: 0px auto;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.square.orange{background-color: #ffffff81;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.square.blue{background-color: #ffffff81;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.partnerContent ul{width:100%;margin: 0px auto;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.partnerContent img{width: 50%;margin: 0px auto;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.partnerContent img:hover{cursor: pointer;transform: scale(1.1);-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}

.subtitle_carousel{font-size:1.2rem;width:80%;text-align:left;margin-top:30px;color:#000}
.title_carousel{font-size: 1.8rem;font-weight: 800;margin-bottom: 10px;color:#000}

@media screen and (min-width: 320px) and (max-width: 599px) {
    .partnerContent ul li{width: 100%;}
    .partnerContent ul li img{width: 40%;margin-top:40px;}
    .tituloItem{margin-bottom: 40px;}
    .title_carousel{font-size: 1.2rem;}
    .subtitle_carousel{font-size:1rem;width:80%;text-align:left;margin-top:10px;color:#000}
    .tituloItem{width: 100%;}
    .carouselContent{width: 98vw;}
    .square{margin-top: 10px;}
    .carousel{flex-wrap: wrap;}
    .square.big{width: 80vw;}
    .square.small{width: 80vw;}
    .square.small .carouselImage{width: 60%;}

    .square.big .carouselImage{opacity: 0;}

    
}

