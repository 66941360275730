.header-perfil{
    padding-left: 90px;
    padding-right: 20px;
}

.profile-content{
}

.filepond--contenido{cursor: pointer;}
.filepond--panel-center{cursor: pointer;}
.filepond--root{cursor: pointer;}
.filepond--drop-label{cursor: pointer;}
.filepond--drop-label label{cursor: pointer;}
#BtnCancel{background-color: #2185d0; color: #fff}
.contentFotoCuentaRequierd{margin-top: 10px}
#autocompletePais{height: 30px;margin-top: 0px;}
#autocompletePais i{margin-top: 10px;}
#ModalBank label{margin:15px auto 0;}
#ModalBank input{height: 30px;}
#ModalBank .ui.inpu{width: 70%;}


#imgPerfilModalUsuario img {border-radius: 50%;width: 150px; height: 150px; object-fit: contain;}

img#imgPerfilUsuario  {border-radius: 50%;width: 150px; height: 150px; object-fit: contain;}
.selecLabelAutocomplete{color: orange; margin: 0px;position: absolute;left: 50%;transform: translateX(-50%);}
.error input{border-color: orange}
/*********************** CLEAR ***********************/
.clear { clear: both; height: 0px; }
.clear5 { clear: both; height: 5px; }
.clear10 { clear: both; height: 10px; }
.clear15 { clear: both; height: 15px; }
.clear20 { clear: both; height: 20px; }
.clear30 { clear: both; height: 30px; }
.clear40 { clear: both; height: 40px; }

/*********************** COLUMNAS ***********************/
.col2 li { float: left; width: 50%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }
.col3 li { float: left; width: 33.33%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }
.col4 li { float: left; width: 25%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }
.col5 li { float: left; width: 20%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }
.col6 li { float: left; width: 16.66%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }
.col7 li { float: left; width: 14.28%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }
.col8 li { float: left; width: 12.5%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }
.col9 li { float: left; width: 11%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }
