.contentIcon{display:flex;flex-direction:row;justify-content: flex-start;}
.iconBuscador{cursor: pointer;display:flex;border-radius:10px;flex-direction:column;justify-content: center;align-items: center;background: #FAFAFA;margin: 5px;padding: 10px;-webkit-box-shadow: 5px 5px 7px -8px #000000; box-shadow: 5px 5px 7px -8px #000000;}
.iconBuscador img{width: 60%}
.iconBuscador p{margin-top: 5px;font-weight: 500;font-size: 13px}
.ContentInputsBuscador{margin-left:5px;background-color:#FAFAFA;-webkit-box-shadow: 5px 5px 7px -8px #000000; box-shadow: 5px 5px 7px -8px #000000;border-radius:15px;}
.InputsBuscador{display: flex;flex-direction: row;justify-content: flex-start;align-items: center;min-height: 55px;position: relative;}
.inputBuscador{position: relative;width: 30%;display: flex;justify-content: flex-start;flex-direction: row;}
.InputsBuscador i{position: absolute;right: 30px;top:50%;transform: translateY(-50%);color:#909090}
.InputsBuscador input{border: none;padding: 10px;text-align: left;padding-left: 20px}
.SeparadorInput{height: 45px;width: 1px;border: 1px solid #909090;background-color: #909090;position: relative;}
.SeparadorInput.circulo::after{content:'';position: absolute;top:50%;left:50%; transform: translate(-50%,-50%);width: 25px;height: 25px;border-radius: 50%;background-color: #fff;border:2px solid #909090;}
.contentBtnSearch{min-width: 7.5vw;height: 6vh}
.btnSearch{cursor: pointer;background-color: #F46363;padding: 10px 15px;color: #fff;font-weight: 600;border-radius: 15px;height: 100%;display: flex;flex-direction: row;align-items: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;width: 100%;}
.btnSearch:hover{opacity: 0.8;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.iconBuscador.activo.blue{background-color: #41D6CD}
.iconBuscador.activo.red{background-color: #F46363}
.iconBuscador.activo.green{background-color: #6DCE6B}
.iconBuscador.activo.grey{background-color: #83859B}

.textIconBuscador.azul{color:#41D6CD}
.textIconBuscador.red{color:#F46363}
.textIconBuscador.green{color:#6DCE6B}
.textIconBuscador.grey{color:#83859B}
.textIconBuscador.activo{color:#fff}
.dropdownVentana{margin: 10px auto;}



@media screen and (min-width: 320px) and (max-width: 599px) {
    .contentIcon{justify-content: center;}
    .ContentInputsBuscador{background-color:transparent;display: flex;flex-direction:column;-webkit-box-shadow: 0px 0px 7px -8px #000000; box-shadow: 0px 0px 7px -8px #000000;}
    .InputsBuscador{display: flex;flex-direction:column;}
    .inputBuscador{background-color: #FAFAFA;margin: 10px auto;width: 100%;border-radius: 10px;}
    .SeparadorInput{display: none;}
    .contentBtnSearch{margin-top: 10px;width: 100%;}
    .btnSearch{width: 100%;margin: 0px auto;justify-content:center}
}
