@import "https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap";

.header-perfil{
    padding-left: 90px;
    padding-right: 20px;
}
.contenedor-paquetes{padding:10px 30px;}
.contenedor-paquetes li{padding:10px;width: 23%}
.ImgPaqueteContent {width:50%; margin: 0 auto;margin-bottom: 30px;}
#DescPaquete p{font-size: 12px;}
#ButtonCompra:hover{opacity: .7}
#ButtonCompra{cursor: pointer;}
.red{color:#FF3333;}
.contentPrice1{padding-top: 150px; display: flex; justify-content: center;}
.contentPrice2{padding-top: 150px; display: flex; justify-content: center;}
.contentPrice3{padding-top: 70px; display: flex; justify-content: center;}
.contentPrice4{padding-top: 85px; display: flex; justify-content: center;}
.contentPrice1 #PricePaquete{color:rgb(246, 42, 0); display: flex; justify-content: center; position: absolute; bottom: 100px;font-size: 25px;font-weight: 600;}
.contentPrice2 #PricePaquete{color:rgb(246, 42, 0); display: flex; justify-content: center; position: absolute; bottom: 100px;font-size: 25px;font-weight: 600;}
.contentPrice3 #PricePaquete{color:rgb(246, 42, 0); display: flex; justify-content: center; position: absolute; bottom: 100px;font-size: 25px;font-weight: 600;}
.contentPrice4 #PricePaquete{color:rgb(246, 42, 0); display: flex; justify-content: center; position: absolute; bottom: 100px;font-size: 25px;font-weight: 600;}
.contentPrice #PriceDivisa{color:rgb(246, 42, 0); display: flex; justify-content: center; position: absolute; bottom: 80px; font-size:10px; }
#TituloPaquete{margin: 20px auto;font-size: 25px;}
.clear80 { clear: both; height: 80px; }

/*Imagenes de paquetes*/

#imgPaquete1:hover {
transform: scale(1.2);
}

#imgPaquete1 {
  transition: all .2s ease-in-out;
}

#imgPaquete1Activo{
  transition: all .2s ease-in-out;
  filter: invert(47%) sepia(98%) saturate(1327%) hue-rotate(331deg) brightness(93%) contrast(89%);

}
#imgPaquete1Activo:hover{
  transform: scale(1.2);
filter: invert(47%) sepia(98%) saturate(1327%) hue-rotate(331deg) brightness(93%) contrast(89%);}


#imgPaquete2:hover {
transform: scale(1.2);
}

#imgPaquete2 {
  transition: all .2s ease-in-out;
}

#imgPaquete2Activo{
  transition: all .2s ease-in-out;
filter: invert(47%) sepia(98%) saturate(1327%) hue-rotate(331deg) brightness(93%) contrast(89%);
}
#imgPaquete2Activo:hover{
  transform: scale(1.2);
  filter: invert(47%) sepia(98%) saturate(1327%) hue-rotate(331deg) brightness(93%) contrast(89%);
}
#imgPaquete3:hover {
transform: scale(1.2);
}

#imgPaquete3 {
  transition: all .2s ease-in-out;
}

#imgPaquete3Activo{
  transition: all .2s ease-in-out;
filter: invert(47%) sepia(98%) saturate(1327%) hue-rotate(331deg) brightness(93%) contrast(89%);
}
#imgPaquete3Activo:hover{
  transform: scale(1.2);
filter: invert(47%) sepia(98%) saturate(1327%) hue-rotate(331deg) brightness(93%) contrast(89%);}
#imgPaquete4:hover {
transform: scale(1.2);
}

#imgPaquete4 {
  transition: all .2s ease-in-out;
}

#imgPaquete4Activo{
  transition: all .2s ease-in-out;
filter: invert(87%) sepia(18%) saturate(924%) hue-rotate(170deg) brightness(103%) contrast(107%);
}
#imgPaquete4Activo:hover{
  transform: scale(1.2);
filter: invert(87%) sepia(18%) saturate(924%) hue-rotate(170deg) brightness(103%) contrast(107%);}


#ModalExpiracion{display: inline-flex; display: inline-flex;position: relative;left: 50%;margin-left: -210px;}
.ui.input {
    margin-left: 20px;
}

/*Estilo de la tarjeta de credito*/
*{
  font-family: Lato;
  }
.bkng-tb-cntnt {
    color: #444;
    font-family: Lato;
    font-size: 16px;
    font-weight: 300;
}
::-webkit-input-placeholder {
   font-style: italic;
}
:-moz-placeholder {
   font-style: italic;
}
::-moz-placeholder {
   font-style: italic;
}
:-ms-input-placeholder {
   font-style: italic;
}


strong {
	font-weight: 700;
}
a {
    cursor: pointer;
    display: block;
    text-decoration: none;
}
a.button {
    border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    text-align: center;
    font-size: 21px;
    font-weight: 400;
    padding: 12px 0;
    width: 100%;
    display: table;
    background: #E51F04;
    background: -moz-linear-gradient(top,  #E51F04 0%, #A60000 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#E51F04), color-stop(100%,#A60000));
    background: -webkit-linear-gradient(top,  #E51F04 0%,#A60000 100%);
    background: -o-linear-gradient(top,  #E51F04 0%,#A60000 100%);
    background: -ms-linear-gradient(top,  #E51F04 0%,#A60000 100%);
    background: linear-gradient(top,  #E51F04 0%,#A60000 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E51F04', endColorstr='#A60000',GradientType=0 );
}
a.button i {
    margin-right: 10px;
}
a.button.disabled {
    background: none repeat scroll 0 0 #ccc;
    cursor: default;
}
.bkng-tb-cntnt {
    width: 800px;
    transform: scale(.7);
    margin: 0 auto;
}
.bkng-tb-cntnt a.button {
    color: #fff;
    float: right;
    font-size: 18px;
    padding: 5px 20px;
    width: auto;
}
.bkng-tb-cntnt a.button.o {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    color: #e51f04;
    border: 1px solid #e51f04;
}
.bkng-tb-cntnt a.button i {
    color: #fff;
}
.bkng-tb-cntnt a.button.o i {
    color: #e51f04;
}
.bkng-tb-cntnt a.button.right i {
    float: right;
    margin: 2px 0 0 10px;
}
.bkng-tb-cntnt a.button.left {
    float: left;
}
.bkng-tb-cntnt a.button.disabled.o {
    border-color: #ccc;
    color: #ccc;
}
.bkng-tb-cntnt a.button.disabled.o i {
    color: #ccc;
}
.pymnts {
    float: left;
    width: 800px;
}
.pymnts * {
    float: left;
}

.sctn-row {
    margin-bottom: 35px;
    width: 800px;
}
.sctn-col {
    width: 375px;
}
.sctn-col.l {
    width: 425px;
}
.sctn-col input {
    border: 1px solid #ccc;
    font-size: 18px;
    line-height: 24px;
    padding: 10px 12px;
    width: 333px;
}
.sctn-col label {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    width: 100%;
}
.sctn-col.x3 {
    width: 300px;
}
.sctn-col.x3.last {
    width: 200px;
}
.sctn-col.x3 input {
    width: 210px;
}
.sctn-col.x3 a {
    float: right;
}
.pymnts-sctn {
    width: 800px;
}
.pymnt-itm {
    margin: 0 0 3px;
    width: 800px;
}
.pymnt-itm h2 {
    background-color: #e9e9e9;
    font-size: 24px;
    line-height: 24px;
    margin: 0;
    padding: 28px 0 28px 20px;
    width: 100%;
}
.pymnt-itm.active h2 {
    background-color: #e51f04;
    color: #fff;
    cursor: default;
}
.pymnt-itm div.pymnt-cntnt {
    display: none;
}
.pymnt-itm.active div.pymnt-cntnt {
    background-color: #f7f7f7;
    display: block;
    padding: 0 0 30px;
    width: 100%;
}

.pymnt-cntnt div.sctn-row {
    margin: 20px 30px 0;
    width: 740px;
}
.pymnt-cntnt div.sctn-row div.sctn-col {
    width: 345px;
}
.pymnt-cntnt div.sctn-row div.sctn-col.l {
    width: 395px;
}
.pymnt-cntnt div.sctn-row div.sctn-col input {
    width: 303px;
}
.pymnt-cntnt div.sctn-row div.sctn-col.half {
    width: 155px;
}
.pymnt-cntnt div.sctn-row div.sctn-col.half.l {
    float: left;
    width: 190px;
}
.pymnt-cntnt div.sctn-row div.sctn-col.half input {
    width: 113px;
}
.pymnt-cntnt div.sctn-row div.sctn-col.cvv {
    background-image: url("../cvv2.png");
    background-position: 156px center;
    background-repeat: no-repeat;
    padding-bottom: 30px;
}
.pymnt-cntnt div.sctn-row div.sctn-col.cvv div.sctn-col.half input {
    width: 110px;
}
.openpay {
    float: right;
    height: 60px;
    margin: 10px 30px 0 0;
    width: 435px;
}
.openpay div.logo {
    background-image: url("../openpay2.png");
    background-position: left bottom;
    background-repeat: no-repeat;
    border-right: 1px solid #ccc;
    font-size: 12px;
    font-weight: 400;
    height: 65px;
    padding: 15px 20px 0 0;
}
.openpay div.shield {
    background-image: url("../security.png");
    background-position: left bottom;
    background-repeat: no-repeat;
    font-size: 12px;
    font-weight: 400;
    margin-left: 20px;
    padding: 20px 0 0 40px;
    width: 200px;
}
.card-expl {
    float: left;
    height: 80px;
    margin: 20px 0;
    width: 800px;
}
.card-expl div {
    background-position: left 45px;
    background-repeat: no-repeat;
    height: 70px;
    padding-top: 10px;
}
.card-expl div.debit {
    background-image: url("../cards2.png");
    margin-left: 20px;
    width: 540px;
}
.card-expl div.credit {
    background-image: url("../cards1.png");
    border-right: 1px solid #ccc;
    margin-left: 30px;
    width: 209px;
}
.card-expl h4 {
    font-weight: 400;
    margin: 0;
}
