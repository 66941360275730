#servicioMeta{
  float: right;
}
.div-principal-envios-vendedor{background-color:'#fff'}
#message-id{ font-size: 18px; padding: 0px 10px}
.returnWagon{text-align: center;font-size: 44px;position: absolute;left: -20px;bottom: -10px;}
.left-container span{font-size: 12px;}
.StatusShipping{display: inline-flex;}
.iconShipping{margin: 0px 10px}
.iconShipping i.icon{font-size: 2em;}
.iconShipping p{margin: 10px 0px;}
.IncotermShipping{float: right; padding: 0px 5px;border-radius: 6px;bottom: 10px;font-weight: 700;}
.IncotermShipping p{ margin: 0px;}
.lineShipping {width: 50px;height: 1px;background: black;position: relative;top: 10px;}
.measurementContent{display: flex;}

.itemMeasu{margin: 0px 15px;}

.TablaMedidas{display: table;width: 100%}
.HeadersContent{display: table-header-group;}
.HeaderItem{display: table-cell;}
.MedidasContent{display: table-row-group;}
.itemCellRow{display: table-row;}
.itemCell{display: table-cell;padding: 10px 0px;border-bottom: 1px solid #d3d3d3 }
.itemCell span{    color: #030303;font-weight: 700;margin-right: 5px;}
.LCLContent{display: inline-flex;}
.LCLContent p,.LCLContent i.icon{margin: 0px 5px;margin-top: 20px;}
.IcotermContent{margin-top: 60px;}
.priceShow{background-color: #fda041;width: 50%;margin: 0px auto;padding: 10px;border-radius: 30px;font-size: 23px;font-weight: 900;color: white;}
#PrecioRutaCard{margin: 10px auto;font-size: 15px;text-align: center;display: block;width: 50%;}
#inputFiltro{display: flex;flex-direction: row;justify-content: center;}

#ModalMar{width: 50%;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
#ModalAereo{width: 50%;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
#ModalTerrestre{width: 50%}
#ModalFerro{width: 50%}
#bodyRutas{height:790px;width: 100%;overflow:hidden;overflow-y: hidden;}
#MenuVentana a.item{ width: 33.3%; text-align: center; display: block;}
#MenuVentana a.item .header{ width: 100%; margin: 0px; margin-top: 5px; font-size: 20px;}
#MenuVentana a.item p{ width: 100%;  margin: 0px; margin-top: 5px; font-size: 11px;}
.statusContainer{ margin-top: 50px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.routeShipping{font-size: 23px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-direction: column;flex-direction: column;width: 100%;position: relative;}
.routeShipping .routeIcon{display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-top: auto;}
.routeShipping .routeIcon i{ margin-bottom: 15px;}
.routeShipping .routeIcon .icono.active{ margin-bottom: 15px; color: #31b336}
.routeShipping .routeIcon .icono.activeBlue{ margin-bottom: 15px;color: #008ced}
.routeShipping .line i#dot-left{font-size: 13px;color: #ccc;width: 14px;height: 14px;background-color: #fff;cursor: pointer;z-index: 2;-webkit-box-shadow: 0 0 0 3px #fff;box-shadow: 0 0 0 3px #fff;}
.routeShipping .line i#dot-right{font-size: 13px;color: #ccc;width: 14px;height: 14px;background-color: #fff;cursor: pointer;z-index: 2;-webkit-box-shadow: 0 0 0 3px #fff;box-shadow: 0 0 0 3px #fff;}
.routeShipping .line i#dot-left-active{font-size: 13px;color: #31b336;width: 14px;height: 14px;background-color: #fff;cursor: pointer;z-index: 2;-webkit-box-shadow: 0 0 0 3px #fff;box-shadow: 0 0 0 3px #fff;}
.routeShipping .line i#dot-left-active-blue{font-size: 13px;color: #008ced;width: 14px;height: 14px;background-color: #fff;cursor: pointer;z-index: 2;-webkit-box-shadow: 0 0 0 3px #fff;box-shadow: 0 0 0 3px #fff;}
.routeShipping .line i#dot-right-active{font-size: 13px;color: #31b336;width: 14px;height: 14px;background-color: #fff;cursor: pointer;z-index: 2;-webkit-box-shadow: 0 0 0 3px #fff;box-shadow: 0 0 0 3px #fff;}
.routeShipping .line i#dot-right-active-blue{font-size: 13px;color: #008ced;width: 14px;height: 14px;background-color: #fff;cursor: pointer;z-index: 2;-webkit-box-shadow: 0 0 0 3px #fff;box-shadow: 0 0 0 3px #fff;}
.routeShipping .line{width: 100%;height: 1px;background-color: #ccc;position: relative;margin-bottom: 10px;display: flex;align-items: center;justify-content: space-between;}
.routeShipping .line.activeBlue{width: 100%;height: 1px;background-color: #008ced;position: relative;margin-bottom: 10px;}
.routeShipping .line.active{width: 100%;height: 1px;background-color: #31b336;position: relative;margin-bottom: 10px;}
#RutasPublicas{height: 100%;display: grid;grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));grid-column-gap: 4rem;overflow: auto;-ms-overflow-style: none;overflow: -moz-scrollbars-none;}
#RutasPublicas::-webkit-scrollbar {display: none;}
.CargoShipping{ margin-top: 50px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.CargoShipping.Ferro{justify-content: space-evenly;}
.CargoShipping .CardCargo{margin: 5px;cursor: pointer; width: 17%; text-align: center; border: 1px solid #ccc; border-radius:10px; padding: 10px 5px; font-size: 12px;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; }
.CargoShipping .CardCargo.active{background-color: #ff9347;border: 1px solid #fff;color: #fff;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; }
.CargoShipping .CardCargo .content{font-size: 9px}
.CargoInsurace{ margin-top: 80px; display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
#SwitchInsurace,#SwitchImo{width: 160px;margin-top: -30px;}

#SwitchInsurace span{font-size: 9px}
#SwitchImo span{font-size: 9px}
.IconBox{text-align: center; padding-top: 20px; font-size: 20px;}
.FormContent{position: relative;}
.FormContent .CandidadInput {padding-top: 20px;width: 90px;margin: 0 auto;left: -30px;position: relative;}
.FormContent .CandidadInput .ui.input{ margin-left: 0px; height: 30px}
.FormContent .CandidadInput .ui.label{height: 30px}
.FormContent .MedidasInput{ margin-top: 10px;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: center;}
.FormContent .MedidasInput .ui.input{width: 20%; margin-left: 0px;height: 30px}
.FormContent .MedidasInput .ui.label{height: 30px;line-height: 10px;}

.FormContent .PesoInput {padding-top: 20px;width: 90px;margin: 0 auto;left: -30px;position: relative;}
.FormContent .PesoInput .ui.input{ margin-left: 0px;height: 30px;}
.FormContent .PesoInput .ui.label{height: 30px;line-height: 10px;}
.FormContent h2{ margin-left: 0px;font-size: 14px;margin-top: 15px;}
.H2transicion{cursor: pointer;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.H2transicion h2::after{content: ' or';width: 30px; height: 30px; position: absolute; margin-top: 1px; opacity: 0;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.H2transicion:hover{transform: translateX(-15px);-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.H2transicion:hover  h2::after{opacity: 1;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.RadiosContent{text-align: center;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: center;}
.RadiosContent .ui.radio.checkbox label{width: 180px;}
.CBMContent {display: inline-flex;justify-content: center;margin-left: -80px;}
.CBMContent .ui.input{width: 30%;padding: 20px;}
.FormTruck{ text-align: center;}
.FormTruck .ui.primary.button{margin: 5px;}

.PadreContentBooking{display: flex;justify-content: space-between;background-color: #ffff}
.contentImgBookingForm{padding: 0px 40px;margin: 10px auto;width: 40%;}
.DescriptionContentBooking{width: 50%;padding: 10px;padding-left: 30px;}
.FormularioContentBooking{width: 46%;padding: 10px;background-color: #fff;box-shadow: -3px 3px 5px 0px #c3c3c3;}
.FormularioBookingHeader{width: 100%;text-align: center;margin: 10px auto;font-size: 18px;font-weight: 600;}
.FormularioBookingHeader p {color: #030303}
.Form.groupBooking{display: flex;justify-content: space-between;width: 90%;margin: 0px auto;align-items: center;}
.Form.groupBooking.simple{display: flex;justify-content: flex-start;width: 90%;}
.Form.groupBooking input{width: 45%;border: 1px solid #c3c3c3;border-radius: 3px;margin: 10px 0px;font-size: 0.9rem;padding: 10px 0px;}
.FormularioContentBooking textarea{width: 100%; height: 60px;border: 1px solid #c3c3c3;border-radius: 3px;margin: 10px 0px;font-size: 0.9rem;}
.FormularioContentBooking .itemInput{width: 45%;}
.MedidasItemTable{width: 100%;display: flex;justify-content: center;}
.itemCellLarge{width: 100%;margin-top: 20px;}
.verticalBtnsContent{display: flex;flex-direction: column;justify-content: space-around;align-items: center;width: 100%;}
.verticalBtnsContent .btnFCL{width: 60%;margin: 5px 0px}
#borderCarta{margin-right: 40px;margin-top: 20px;}
#CardSolo{width: 460px;}

#div-stepper-envios{
width:70%;
margin-left: 40%;
margin-top: 5%;
transform: scale(.7);
}

.btnGrupoTipo{width: 50%;display: flex;justify-content: center;position: relative;margin: 0px auto;padding-top: 30px;margin-bottom: 30px;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.btnTipo{position: relative;width: 30%;background: #fff;padding: 5px;cursor: pointer;margin: 0px 5px;border-radius: 10px;display: flex;flex-direction: column;align-items: center;align-self: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;box-shadow: 3px 2px 5px 2px #0000001c;}
.contentIcon{display: flex;flex-direction: column;align-items: center;}

/**************** HEADER BUSCADOR **********************/

.headerBuscadorLanding{position: absolute;top: 0px;z-index: 99;width: 100%;}
.headerContenedor{width:100%;margin-top:140px}
.headerTextContent{width: 80%;margin: 0px auto;margin-bottom: 40px;}
.headerTitulo{font-size: 2.5rem;font-weight: 800;margin-bottom:10px;color:#3E3D3D}
.headerSubtitulo{width:60%;font-size:1.2rem}
.headerBuscadorContent{position:relative;z-index:11}
.headerImgContent{position:absolute;right:0;top:0;width:35%;z-index:9}





.contentIcon.air:hover{color: #E68551;text-decoration: none;transition: 0.4s color linear;}

.contentIcon.mar:hover{color: #5B91DB;text-decoration: none;transition: 0.4s color linear;}

.contentIcon.carretera:hover{color: #5FDE9F;text-decoration: none;transition: 0.4s color linear;}

.contentIcon.ferrocaril:hover{color: #A7AA66;text-decoration: none;transition: 0.4s color linear;}

/************VENTANA DE COTIZACION ***************/
.ContenidoPadre{position: relative;width: 99%;margin: 0px auto;display: flex;flex-direction: column;justify-content: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.ContenidoPadre section{width: 95%;margin: 0px auto;padding: 10px;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.imgContenido{width: 60%;margin: 10px auto;}
.imgContenidoPallet{width: 30%;margin: 10px auto;}
.imgContenidoBBulk{width: 50%;margin: 10px auto;}
.AccordionItem{cursor: pointer;display: flex;flex-direction:column;justify-content: center;align-items: center;width: 95%;border: 1px solid #c3c3c3;margin: 10px auto;border-radius: 3px;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.AccordionItem:hover{border: 1px solid #ff9347;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.AccordionHeader{display: flex;flex-direction:row;justify-content: space-between;align-items: center;width: 95%;margin: 0px auto;margin-top: 10px;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.divider{width: 90%;height: 1px;background-color: #c3c3c3;margin: 10px auto;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.divider.collapse{transform-origin: center;transform: scaleX(0);height: 1px;background-color: #c3c3c3;margin: 0px auto;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}


.MenuVentana{display: flex;width: 90%;margin: 0px auto;border-radius: 5px;border: 1px solid #c3c3c3}
.MenuVentana.dos{width: 66%}
.MenuVentana.dos .itemMenu {width: 50%}
.tituloModal{font-size: 20px;font-weight: 600;margin: 10px 0px;margin-left: 10px}
.itemMenu {padding: 10px 5px;margin: 0px 5px;width: 33%;text-align: center;cursor: pointer;position: relative;}
.itemMenu h4{font-size: 15px;color:#505050}
.itemMenu.Fcl{border-right: 1px solid #c3c3c3}
.itemMenu.Lcl{border-right: 1px solid #c3c3c3}
.itemMenu.Fcl::before {content:''; opacity: 0;display: none;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.itemMenu.Fcl:hover::before{content:'FULL CONTAINER LOAD';position: absolute;background-color: rgba(0,0,0,0.5);color: #fff;padding: 5px 10px;bottom: -30px;transform: translateX(-50%);left: 50%;min-width: 200px;width:100%;opacity: 1;display: block;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.itemMenu.Lcl::before {content:''; opacity: 0;display: none;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.itemMenu.Lcl:hover::before{content: 'LESS CONTAINER LOAD';position: absolute;background-color: rgba(0,0,0,0.5);color: #fff;padding: 5px 10px;bottom: -30px;transform: translateX(-50%);left: 50%;min-width: 200px;width:100%;opacity: 1;display: block;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.itemMenu.Bbulk::before {content:''; opacity: 0;display: none;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.itemMenu.Bbulk:hover::before{content: 'BULK & BREAK BULK';position: absolute;background-color: rgba(0,0,0,0.5);color: #fff;padding: 5px 10px;bottom: -30px;transform: translateX(-50%);left: 50%;min-width: 200px;width:100%;opacity: 1;display: block;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.itemMenu::after {content:'';height: 2px; width: 0%; display: block;background-color:#ff9347;position: absolute;bottom: -2px;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.itemMenu.Activo::after {content:'';height: 2px; width: 100%;display: block;background-color:#ff9347;position: absolute;bottom: -2px;left: 50%;transform: translateX(-50%);-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}

.itemFormConent{display: flex; width: 90%;flex-direction: row;justify-content: space-around;align-items: center;margin: 5px auto;}
.itemFormConent input {border: 1px solid #c3c3c3;margin: 5px 2px;border-radius: 3px;padding: 4px 10px}
.botonesFcl{display: flex;flex-direction: row;justify-content: space-around;}
.btnFCL{cursor: pointer;background-color: #ff9347;text-align: center;border-radius: 3px;padding: 10px 15px;margin: 0px 5px;font-size: 15px;width: 30%;opacity: 0.5;color: #fff;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.btnFCL:hover{background-color: #ff9347;opacity: 1;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.btnFCL.Activo{background-color: #ff9347;opacity: 1;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.btnAddItem{position: absolute;right: 0px;top: -40px;max-width: 150px;cursor: pointer;padding: 7px;display: flex;width: 25%;background-color: #6abb78;opacity: 0.8;justify-content: center;align-items: center;border-radius: 5px;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.btnAddItem:hover{opacity: 1;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.btnAddItem i{color: #fff}
.btnEliminate.item{width: 30px;height: 30px;display: flex;justify-content: center;align-items: center;position: absolute;right: -5px;background-color: #ef6c6c;border-radius: 5px;cursor: pointer;}
.btnEliminate.item i {margin: 0px; color: #fff}
.formTotal{display: flex;justify-content: space-around;margin: 20px auto;}
.formTotal p {font-size: 15px}
.formTotal span {color: #505050;font-weight: 600;margin-right: 10px}
.dropdown{margin: 0px auto;margin-bottom: 30px}

.iconTipo{width: 40%;margin: 0px 10px;margin-bottom: 5px;}
.btnTipo p {font-weight: 600;font-size: 13px;}
/************ITEM RUTAS ***************/
#RutasPublicas ul{margin-top: 30px}
.CardItemCotizacion{width: 90%;margin: 10px auto;background-color: #fff;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;box-shadow: 3px 2px 5px 0px #00000029;}
.CardItemCotizacion img{height: 16vh;object-fit: cover;width: 100%;}
.CardItemCotizacion .CardItem-descripcion{padding: 10px;margin-top: 20px;position: relative;}
.CardItemCotizacion .CarItem-descripcion-float{position: absolute;top: -35px;display: flex;flex-direction: row;justify-content: flex-end;width: 95%;}
.CardItemCotizacion .CarItem-descripcion-float p {cursor: pointer;background-color: #fff;border-radius:30px;margin: 0px 10px;padding: 5px 10px;box-shadow: 3px 2px 5px 0px #00000069;color: #484848;font-weight:600;}
.CardItemCotizacion .titulo{color: #484848;font-weight:600;}
.CardItemCotizacion .subtitulo{margin: 0px;line-height: 25px;}
.CardItemCotizacion .precio{cursor: pointer;text-align: center;width: 11vw;background-color: #e8850a;border-radius:30px;margin: 10px 0px 10px auto;padding: 5px 10px;box-shadow: 3px 2px 5px 0px #00000049;color: #ffff;font-weight:600;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.CardItemCotizacion .precio:hover{transform: scale(1.1);transform-origin: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}

/************ITEM RUTAS RANDOM***************/
.RutasRandomContent{margin-bottom: 50px;}
.RutasRandomTittle h3{text-align: center;font-size: 2vw;margin: 20px auto;color:#e8850a}
.scrollHorizontal{display: flex;flex-direction: row;width: auto;overflow-y: hidden; overflow-x: auto;white-space: nowrap;}
.scrollHorizontal::-webkit-scrollbar{background-color: rgba(0,0,0,0)}
.scrollHorizontal::-webkit-scrollbar-thumb{background-color: rgba(0,0,0,0)}
.scrollHorizontal::-webkit-scrollbar-track{background-color: rgba(0,0,0,0)}
.contentITems{display: flex;flex-direction: row;width: auto;overflow-y: hidden; overflow-x: auto;white-space: nowrap;}

.itemRutaRandom{cursor: pointer;min-width: 340px;width: 30%;background-color: #fff;margin: 5px 20px;position: relative;padding: 10px 5px;border-radius: 5px;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;box-shadow: 3px 2px 5px 2px #0000001c;}
.itemRutaRandom:hover{transform: scale(1.04);transform-origin: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.iconType{width: 10%;position: absolute;}
.react-multi-carousel-item{max-width: 360px;min-width:360px;}
.carousel-container{padding: 10px 0px;width: 90%;max-width: 1200px;margin: 0px auto;}
.iconCheck{position: absolute;right: 5px;top:10px;}
.iconCheck i{font-size: 1.5rem;}
.dataRate{margin-left: 15%}
.dataRate p{margin: 0px;color:#3D3B53;margin-top: 3px;}
.dataRate h3{ margin: 0px;color:#3D3B53;margin-top: 3px;margin-left: 10px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 100%;}
.rate{display: flex;flex-direction: row;justify-content:center;width: 100%;margin-top: 15px;}
.type{position: absolute;left: 10px;bottom: 5px;}

/*********************** Modal Precotizacion ***********************/

.confirmWindow{display: flex;justify-content: space-around;align-items: center;}
.confirmWindow img{width: 30%}
.formModal{width: 50%;text-align: center;}
.formModal input{border: 1px solid #c3c3c3;border-radius: 3px;padding: 5px}
.btnModal.positive{cursor: pointer;margin: 0px auto;width: 50%;margin-top: 30px;padding: 10px;background-color: #f43130;border-radius: 3px;color:#fff;transform-origin: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.btnModal.positive:hover{transform: scale(1.05);transform-origin: center;opacity: 0.9;transform-origin: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.btnModal.skiping{cursor: pointer;margin: 0px 0px 0px auto;text-align: right;width: 30%;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.btnModal.skiping:hover{margin: 0px 0px 0px auto;width: 30%;transform: scale(1.05);transform-origin: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}

/*********************** Landing ***********************/
.grandiente{
  width: 90%;
  height: 100%;
  background: radial-gradient(32.51% 32.51% at 50% 50%, rgba(254, 0, 61, 0.79) 0%, rgba(253, 253, 253, 0) 100%);
  opacity: 0.7;
  position: absolute;
  z-index: 0;
}
.grandiente.naranja{
  width: 30%;
  height: 40%;
  left: 20%;
  background: radial-gradient(32.51% 32.51% at 50% 50%, rgba(254, 122, 0, 0.79) 0%, rgba(253, 253, 253, 0) 100%);
}

.landingHome{width: 85%;display: flex;flex-direction: column;align-self:center;align-items: center;justify-content: center;margin: 0px auto;}
.tituloContent{margin: 12vh auto 8vh auto;}
.titulo-seccion{font-size: 2rem;font-weight: bold;text-align: center;color: #3E3D3D;margin-bottom: 5px}
.underline{background-color:#F46363;width:100%;height: 1px}
.contentItemFreigt{display:flex;flex-direction:row;width:100%;margin-top: 100px;justify-content: center;align-items: center;border-radius:10px;position: relative;z-index: 3;}
.contentItemFreigt :first-child{border-bottom-left-radius: 15px;border-top-left-radius: 15px;}
.contentItemFreigt :last-child{border-bottom-right-radius: 15px;border-top-right-radius: 15px;}
.itemFreigt{cursor: pointer;background-color: #F5F2F2;padding: 10px 20px;width: 28.5%;}
.itemFreigt.activo{background-color: #F46363}
.itemFreigt.activo p {color: #fff}
.itemFreigt p {font-size:1.2rem;font-weight: 600;color:#3E3D3D;text-align: center;}

/*********************** MEDIAS QUERIES ***********************/





@media screen and (min-width: 320px) and (max-width: 479px) {
	.contenedor { width: 95%; }
	/* .seccion1{ border: 1px solid red; } */
  /************Inputs cotizacion ***************/

  .btnGrupoTipo{width: 90%}
  .btnTipo{padding: 5px}
  .iconTipo{width: 50%;}
  .btnTipo p{font-size: 12px}
  #inputFiltro{flex-direction: column;}
  .ui.input{margin: 5px 20px;}
  ul.col6 li {width: 100%}
  .CardItemCotizacion .CarItem-descripcion-float p {font-size: 10px}
  .CardItemCotizacion .subtitulo{font-size: 13px;line-height: 18px}
  .CardItemCotizacion .precio{width: 40vw}

  .itemRutaRandom{min-width: 140px;width: 20%;margin: 5px 10px;padding: 10px 5px;}
  .iconType{width: 10%;position: absolute;}
  .react-multi-carousel-item{display: none}
  .carousel-container{display: none;}
  #ModalMar{width: 85%;}
  #ModalAereo{width: 85%;}
  #ModalTerrestre{width: 85%}
  #ModalFerro{width: 85%}
  .contentItemFreigt{
    flex-direction: column;
    margin-bottom: 30px;
  }
  .contentItemFreigt :first-child{border-bottom-left-radius: 15px;border-top-left-radius: 15px;}
  .contentItemFreigt :last-child{border-bottom-right-radius: 15px;border-top-right-radius: 15px;}
  .itemFreigt{border-radius: 15px;width:100%;margin-top:10px;}
  .grandiente{width:100%;height: 50%;}
}

@media screen and (min-width: 480px) and (max-width: 599px) {
	.contenedor { width: 95%; }
	/* .seccion1{ border: 1px solid blue; } */

  /************Inputs cotizacion ***************/

  .btnGrupoTipo{width: 90%}
  .btnTipo{padding: 5px}
  .iconTipo{width: 50%;}
  .btnTipo p{font-size: 12px}

  /************ITEM RUTAS ***************/
  ul.col6 li {width: 100%}
  .CardItemCotizacion .CarItem-descripcion-float p {font-size: 10px}
  .CardItemCotizacion .subtitulo{font-size: 13px;line-height: 18px}
  .CardItemCotizacion .precio{width: 40vw}

  .itemRutaRandom{min-width: 240px;width: 20%;margin: 5px 10px;padding: 10px 5px;}
  .iconType{width: 10%;position: absolute;}
  .react-multi-carousel-item{max-width: 270px;min-width:270px;}
  .carousel-container{padding: 10px 0px}
  .iconCheck{right: 5px;top:10px;}
  .iconCheck i{font-size: 1.5rem;}
  .dataRate{margin-left: 15%}
  .dataRate p{font-size: 10px;}
  .dataRate h3{font-size: 11px;}
  .type h3{font-size: 11px;}
  #ModalMar{width: 85%;}
  #ModalAereo{width: 85%;}
  #ModalTerrestre{width: 85%}
  #ModalFerro{width: 85%}
  .contentItemFreigt{
    flex-direction: column;
    margin-bottom: 30px;
  }
  .contentItemFreigt :first-child{border-bottom-left-radius: 15px;border-top-left-radius: 15px;}
  .contentItemFreigt :last-child{border-bottom-right-radius: 15px;border-top-right-radius: 15px;}
  .itemFreigt{border-radius: 15px;width:100%;margin-top:10px;}
}

@media screen and (min-width: 600px) and (max-width: 1023px) {

  /************Inputs cotizacion ***************/

  .btnGrupoTipo{width: 70%}
  .btnTipo{padding: 5px}
  .iconTipo{width: 30%;}
  .btnTipo p{font-size: 14px}

  /************ITEM RUTAS ***************/
  ul.col6 li {width: 33.33%}
  .CardItemCotizacion .CarItem-descripcion-float p {font-size: 9px}
  .CardItemCotizacion .titulo{font-size: 12px;}
  .CardItemCotizacion .subtitulo{font-size: 11px;line-height: 18px}
  .CardItemCotizacion .precio{width: 20vw}

  .itemRutaRandom{min-width: 240px;width: 20%;margin: 5px 10px;padding: 10px 5px;}
  .iconType{width: 10%;position: absolute;}
  .react-multi-carousel-item{max-width: 270px;min-width:270px;}
  .carousel-container{padding: 10px 0px}
  .iconCheck{right: 5px;top:10px;}
  .iconCheck i{font-size: 1.5rem;}
  .dataRate{margin-left: 15%}
  .dataRate p{font-size: 10px;}
  .dataRate h3{font-size: 11px;}
  .type h3{font-size: 11px;}
  #ModalMar{width: 60%;}
  #ModalAereo{width: 60%;}
  #ModalTerrestre{width: 60%}
  #ModalFerro{width: 60%}
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
	.contenedor { width: 95%; }
  /************ITEM RUTAS ***************/
  ul.col6 li {width: 25%}
  .CardItemCotizacion .CarItem-descripcion-float p {font-size: 10px}
  .CardItemCotizacion .subtitulo{font-size: 12px;line-height: 18px}
  .CardItemCotizacion .precio{width: 11vw}

  .itemRutaRandom{min-width: 240px;width: 20%;margin: 5px 10px;padding: 10px 5px;}
  .iconType{width: 10%;position: absolute;}
  .react-multi-carousel-item{max-width: 270px;min-width:270px;}
  .carousel-container{padding: 10px 0px}
  .iconCheck{right: 5px;top:10px;}
  .iconCheck i{font-size: 1.5rem;}
  .dataRate{margin-left: 15%}
  .dataRate p{font-size: 12px;}
  .dataRate h3{font-size: 13px;}
  .type h3{font-size: 13px;}

}

@media screen and (min-width: 1440px) and (max-width: 1840px){
	.contenedor { width: 95%; }
  /************ITEM RUTAS ***************/
  ul.col6 li {width: 25%}
  .CardItemCotizacion .CarItem-descripcion-float p {font-size: 10px}
  .CardItemCotizacion .subtitulo{font-size: 12px;line-height: 18px}
  .CardItemCotizacion .precio{width: 11vw}

}


@media screen and (min-width: 1841px) and (max-width: 2240px){
	.contenedor { width: 95%; }
	/* .seccion1{ border: 1px solid black; } */

}
@media (min-width: 2241px){
	.contenedor { width: 95%; }
	/* .seccion1{ border: 1px solid blue; } */
}









