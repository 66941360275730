.contentFooterBackground{background-color:#FAFAFA;width:100%;padding-top: 10vh;padding-bottom: 30px;margin-top: 13vh;-webkit-box-shadow: -1px -2px 10px 3px #00000026;box-shadow: -1px -2px 10px 3px #00000026}
.footerContent{display: flex;flex-direction: column;width:90%;margin: 0px auto;}
.TermContent{display:flex;flex-direction: column;justify-content: flex-start;align-items: center;}
.logo_footer img{width: 100%}
.TermContent a{color:#FF0000;margin-bottom: 30px}
.itemFooterContent{width: 100%;display:flex;flex-direction: row;justify-content: space-around;align-items: center;}
.itemFooter img{width: 75%;margin: 0px auto;margin-top: -15vh}
.Footer{color:#FF0000;margin-bottom: 10px;text-align: left;}
.FooterDown{width: 90%;margin-top: 7vh;text-align: center;}
.redes_sociales{display: flex;flex-direction: row;justify-content:center; align-items: center;}


@media screen and (min-width: 320px) and (max-width: 599px) {
    .itemFooterContent{flex-direction: column;text-align: center;}
    .itemFooter{margin-top: 40px;}
}
