.UsuariosAdmin .contentUsuarios{position:relative;}
.UsuariosAdmin .column{position: relative;padding: 10px;display: flex;justify-content: center;align-items: center;flex-direction: column;}

.UsuariosAdmin .column.uno{width: 10%;}
.UsuariosAdmin .column.tres{width: 30%;}
.UsuariosAdmin .column.siete{width: 70%;}
.UsuariosAdmin .grid{display: flex;justify-content: center;align-items: flex-start;}
.UsuariosAdmin .grid.horizontal{flex-direction: row;}

.UsuariosAdmin .Acciones.header{width: 100%;display: flex;flex-direction: row;justify-content: flex-end;margin-top: 20px}
.UsuariosAdmin .label.ribbon.right{padding: 5px 10px;background-color: #00b5ad;position: absolute;right: -10px;color:#fff;font-weight: bold;border-top-right-radius: 3px;border-top-left-radius: 3px;border-bottom-left-radius: 3px;}
.UsuariosAdmin .label.ribbon.right.bloqueado{padding: 5px 10px;background-color: #EA5757;position: absolute;right: 0px;z-index: 10;margin-top:50px;color:#fff;font-weight: bold;border-top-right-radius: 3px;border-top-left-radius: 3px;border-bottom-left-radius: 3px;}
.UsuariosAdmin .label.ribbon.right::after{content:'';position: absolute;right: 0px;bottom: -10px;width: 0;height: 0;border-top: 10px solid #039c96;border-right: 10px solid transparent;}
.UsuariosAdmin .label.ribbon.right.bloqueado::after{content:'';position: absolute;right: 0px;bottom: -10px;width: 0;height: 0;border-top: 10px solid #EA5757;border-right: 10px solid transparent;}
.UsuariosAdmin .item{display: flex;flex-direction: row;background-color: #fff;width: 100%;padding: 10px 15px;margin-bottom: 15px;position:relative;box-shadow: 0 1px 0 1px rgba(0,0,0,.03);}
.UsuariosAdmin .imgContent{width: 170px;height: 80px;}
.UsuariosAdmin .item img{width: 100%;height:100%;object-fit: cover;}
.UsuariosAdmin .itemContent{margin-left: 10px}
.UsuariosAdmin .itemHeader{font-weight: bold;}
.UsuariosAdmin .itemDescripcion{}
.UsuariosAdmin .ui.dropdown .menu>.item>.image, .ui.dropdown .menu>.item>img, .ui.dropdown>.text>.image, .ui.dropdown>.text>img{width: 30px;height: 30px}
.UsuariosAdmin .ui.dropdown .menu>.item>span, .ui.dropdown>.text>span{padding-left: 10px}
.UsuariosAdmin .input{display: flex;flex-direction: column;justify-content: center;align-items: center;}
.UsuariosAdmin .input.label label{margin-top: 0px;margin-bottom: 5px;}
.UsuariosAdmin .input input {width: 90%;border: 1px solid #c1c1c1;border-radius: 3px;padding: 5px; background-color: #fff}

.UsuariosAdmin .dropdownUsuarios{overflow-y: scroll;max-height: 300px;background-color:#fff;position: absolute;transform: translateY(calc(50% + 45px));width:90%;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.UsuariosAdmin .dropdownUsuarios-item{cursor: pointer;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;padding: 5px 10px}
.UsuariosAdmin .dropdownUsuarios-item:hover{background-color: #c1c1c1;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
