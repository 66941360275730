.firstContainer{right: 0;position: relative;width: 100%;height: 44rem; display: flex;align-items: center;color:#FEF5E1;z-index: 2;}
.firstContainer video{position: absolute;top: 0;left: 0;width: 100%;height: 100%;object-fit: cover;margin: .4rem;border-radius: 4vh; }
.firstContainer .videoOverlay{position: absolute;top: 0;left: 0;width: 100%;height: 100%;mix-blend-mode: overlay; background:linear-gradient(rgba(244, 99, 99 , 0.81), rgba(244, 99, 99 , 0.21));margin: .4rem;border-radius: 4vh;}

.firstContainer .text{width: 77%;padding: 9rem; padding-top: 5rem;position: absolute;}
.firstContainer .text h1{font-family: 'Poppins';font-weight: 700;color: #FFFFFF;font-size: 4.1rem;letter-spacing: -0.02rem;}
.firstContainer .text p{font-family: 'Poppins';font-weight: 500;font-size: 1.1rem;color: #FFFFFF;line-height: 2rem;}

@media screen and (min-width:1350px){
    .firstContainer .caja1{padding-left: 10%;}
    .firstContainer .caja1 p{font-size: 100px;}
    .firstContainer .text{width: 80%;}
}
@media screen and (min-width:1850px){
    .firstContainer .caja1{padding-left: 10%;margin-top: 3rem;}
    .firstContainer .caja1 p{font-size: 125px;}
    .firstContainer .text{width: 50%;}

}


@media (max-width:990px){
    .firstContainer .text{width: 90%;padding: 2rem;padding-top: 0rem;}
    }
@media (max-width: 575.98px) { 
    .firstContainer .text h1{font-size: 2.9rem;}
    .firstContainer .text p{font-size: 1rem;}
    .firstContainer .text{width: 95%}
 }