.MenuContent{display: flex;flex-direction: row;padding: 10px;top: 0;justify-content: center;align-items: center;position: absolute;width: 100%;max-width: 1200px;z-index: 999;left: 50%;transform: translateX(-50%);}
.Logo{width: 10%}
.Logo img{width: 100%}
.MenuItemContent{width: 50%;margin-left: 5vw;}
.MenuItemContent p{text-align: center;}
.Menu.rigth{position: relative;width: 30%;display: flex;flex-direction: row;justify-content: center;}
.btnLogin{background-color: #F46363;padding: 5px 30px;border-radius: 20px;}
.btnLogin p{color:#fff;text-align: center;}

.navbar{width: 98%;height: 115px;justify-content: center;position:absolute;z-index: 12;}
.navbar .links{display: flex;justify-content: flex-end;align-items: center;width: 100%;height: 100%;color: #ffffff; }
.links p{margin-left: 4rem; text-decoration: none;font-weight: 500;font-size: 14px;font-family: 'Poppins'; line-height: 107.5%;color: #ffff;letter-spacing: 0.185em;}
.navbar .links img{margin-right: 15rem;width: 13rem;}
.navbar .links .Boton{padding: 1rem;padding-left: 3rem;padding-right: 3rem;background-color: #F46363;border-radius: 1rem;}
  .topnav {overflow: hidden;background-color: rgb(255 255 255 / 90%);position: absolute;width: 100%; z-index: 20}
  .topnav img{height: 5rem;padding-left: 2rem;width: 13rem;}
  .topnav a {color: #737476;padding: 14px 16px;text-decoration: none;font-size: 17px;display: block;}
  .topnav a.icon {font-size: 36px;padding: 2rem;color: #ffffff;display: block;position: absolute;right: 0;top: 0;}
  

  
  .topnav .active {
    color: white;
  }
@media screen and (min-width:1350px){
.navbar .links {justify-content: center;}
.navbar .links p{font-size: 16px;}
}

@media screen and (min-width:1850px){
    
}