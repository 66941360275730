.header-rutas{padding-right: 20px;}
.headerContent{position: relative;margin-top: 40px}
#newForm{background-color: white;}
#ButtonsExcel{margin-left: 35%;margin-top: 2%;}
#btnEdit:hover{opacity: .6}
#ButtonsExcel .field .ui.input{margin-left: 0}
#ButtonsExcel .field {margin-left: 20px}
.ui.form .inline.fields .field {margin-left: 20px;}
#warningRutas{font-family: Lato;font-size: 16px;color:#dc9f01;}
#formRutas label{display: flex;justify-content: center;}
#formRutas .ui.input{margin-left: 0px;}
#formRutas .ui.form .inline.fields .field{margin-left: 0px;}
.tituloSeccion{display: flex;flex-direction: row;justify-content: center;align-items: center;position: absolute;top: 40%;transform: translate(0%,-50%);color: #fff;background-color: #00000050;width: 100%;padding: 10px}
.tituloSeccion i {font-size:2vw;color: #fff}
.tituloSeccion p {font-size:2vw;color: #fff}

/********** Actions BTNS *************/
.actionsBtnsContent{display: flex;flex-direction: row;width: 100%;justify-content: space-between;;align-items: center;margin: 10px auto; }
.actionsBtns{display: flex;flex-direction: row;justify-content: flex-end;align-items: center;margin-bottom: 10px;}
.actionsBtns.left{justify-content: flex-start;}
.actionsBtns.right{justify-content:space-between;width: 100%;}
.actionsBtns.centerBetween{justify-content: flex-end;width: 100%;}
.actionsBtns .btn{cursor: pointer;border-radius: 3px;text-align: center;padding: 5px;width: 15%;min-width: 100px;margin: 0px 10px;border: 1px solid #BDBDBD;background-color: #fff;justify-content: center;align-items: center;}
.actionsBtns .btn.positive{border: 1px solid #5BC162; }
.actionsBtns .btn.orange{border: 1px solid #EB902E; }
.actionsBtns .btn.pending{background-color: #D68F3E;color: #fff;border: none;font-weight: 600;}
.actionsBtns .btn.negative{background-color: #DA4343;color: #fff;border: none;font-weight: 600;}
.Action.row{margin: 0px 5px;margin-top: 5px;}
.btn.icon.negative{background-color: #DA4343;text-align: center;border-radius: 5px;padding: 5px;cursor: pointer;}
.btn.icon.positive{background-color: #5BC162;text-align: center;border-radius: 5px;padding: 5px;cursor: pointer;}
.btn.icon i {margin: 0px 5px;color: #fff;}
.bttnAgregar{cursor: pointer;position: absolute;right: 10px;top: 0px;width: 10%;max-width: 100px;padding: 10px;color: #fff;border-radius: 5px;text-align: center;font-weight: 600;}
.bttnAgregar.positive{background-color: #4FB859;}
.bttnAgregar.pending{background-color: #D68F3E;}

.PaginacionFormulario{display: flex;width: 100%;justify-content: center;align-items: center;margin: 30px 0px;padding-bottom: 40px;}

/********** FORMULARIO NUEVA RUTA  *************/

.TableContent{width: 98%;margin: 0px auto;margin-top: 30px;}
.headerItem{background-color: #fff;border: 1px solid #c3c3c3;font-size: 10px;}
.headerItem p {color: #101010;font-weight: bold;}
.FormContent{ border-spacing: 0px;border-collapse: separate;border-radius: 10px;}
.TableContent{border-radius: 10px}
.FormRow .inputRuta{background-color: #fff;border: 0.5px solid #c3c3c3;}
.FormRow .inputRuta input{border: none}
.FormRow select{width: 100%;min-width: 90px;border: none}
.inputOrigen{width: 10%;min-width: 140px}
.inputDestino{width: 10%;min-width: 140px}
.inputDate{margin: 0px 15px}
.ui.selection.dropdown{min-width: 80px;margin: 0px}
.TableContent .ui.checkbox .box, .ui.checkbox label{width: 30px;margin: 0px}
.ui.dropdown .menu>.item {display: flex;align-items: center;}
