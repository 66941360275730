#inputFiltros{width: 84%;margin-top: 15px;left: 12%;padding-bottom: 3vh;}
#inputFiltros *{margin-right: 8%;width: 40%;}
.InfoCompany p{font-size: .8em;color: grey;padding-top: 10px;}
.InfoCompany h4{margin-top: 0px;font-size: 1em;color:#343534}
.InfoCompany {width: 100%;padding-top: 0;}
.div-principal-Catalogo{overflow: auto;}
#RutasPublicas{height: 100%;display: grid;column-count: 3;}
#borderCarta{margin-right: 40px;margin-top: 20px;}
#Paginacion{position: relative;margin-top: 100px;margin-bottom: 50px;display: flex;justify-content: center;align-items: center;}
#CardSolo{width: 460px;}
#div-stepper-envios{width:70%;margin-left: 40%;margin-top: 5%;transform: scale(.7);}
.left-container {padding-left: 1vh;padding-top: 0px;margin-left: 0;float: left;position: relative;width: 30%;background-color: #F1F1F1;height: auto;max-height: 70vh;overflow-y: auto;}
.right-container {padding-top: 0px;padding-left: 1vh;margin-left: 1vh;position: relative;float: left;width: 68%;height: auto;max-height: 70vh;}


/***************HEADER DIRECTORIO*************************/

.headerDirectorio{width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center;position: relative;margin-bottom: 40px;padding-top: 30px;}
.headerDirectorio p{font-size: 20px;color: #fff;font-weight: 600}
.inputSelect{position: relative;display: flex;flex-direction: row;justify-content: flex-end;padding-bottom: 20px;margin-right: 40px;}
.inputSelect select.selectCountry{border-radius: 5px;padding: 10px;font-size: 14px;padding-left: 20px;}
.inputSelect select.selectCountry,option {color: grey;font-size: 15px;}
.ContentListaCards{width: 100%;}
.Paginacion{width: 100%;position: relative;text-align: center;margin: 30px 0px;}
.contentCardCatalogo .CardCatalogo{position: relative;overflow:hidden;cursor: pointer;border-radius: 10px;width: 95%;margin: 10px auto;background-color: #fff;height: 140px;display: flex;flex-direction: row;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.contentCardCatalogo .CardCatalogo:hover{transform: scale(1.06);opacity: 0.6; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.contentAvatar{width: 45%}
.triangulo{width: 100%;}
.avatar{padding: 2px;width: 20%; height: 6.5vw;border-radius: 50%;position: absolute;left: 10%;top: 10%;background-color: #fff;box-shadow: 3px 2px 5px 2px #0000001c;display: flex;justify-content: center;align-items: center;}
.avatar img{width: 90%; height: 5.7vw;border-radius: 50%;background-color: #fff;object-fit: contain;box-shadow: 0px 0px 10px 4px #0000001c;}
.CardContent {width: 85%;padding-top: 5px}
.CardContent h3{color: #3D3B53;margin: 0px;width: 90%}
.CardContent p{color: #3D3B53;margin: 0px}
.CardContent .titulo{font-size: 22px}
.CardContent .subtitulo{font-size: 16px}
.CardContent.Down{display: inline-flex;position: absolute;bottom: 10px;align-items: flex-end;}
.insignia{justify-content: center;text-align: center;}
.insignia h3{font-size: 13px}
.insignia p{font-size: 10px}
.rateCard{float: right;position:absolute;right: 10px;bottom: 10px}


/***************HEADER DIRECTORIO*************************/

.PerfilPublico section{width: 90%;margin: 0px auto;}
.PerfilPublico .imagenPortadaPublico{position: relative;width: 100%;margin: 0px auto;height: 30vh;background-position: center;background-repeat: no-repeat;background-size: cover;}
.PerfilPublico .ContentImagenPerfilPublico{position: absolute;left: 10vw;top:15vh; margin: auto 0px auto 0px;background-color:#fff; width: 13vw;height: 13vw;border-radius: 50%;padding: 3px;border: 2px solid #FCA043;}
.PerfilPublico .imagenPerfilPublico{width: 13vw;height: 13vw;border-radius: 50%;background-position: center;background-repeat: no-repeat;background-size: contain;}
.contentInfoHeader{padding: 20px;padding-left: 25vw;width: 100%;box-shadow: 3px 2px 5px 2px #0000001c;}
.contentInfoHeader h3 {font-size: 2em;margin: 0px}
.contentInfoHeader p {margin: 5px 0px;font-size: 1.3em}
.contentPerfilHeaterItem{display: flex;flex-direction:row;justify-content:flex-start;align-items: center;}
.perfilHeaderITem{display: flex;flex-direction:row;justify-content:flex-start;align-items: center;margin-right: 20px}
.perfilHeaderITem p{font-size: 1em}


.seccion1{background-color: #f5f5f5}
.ContentContenido{display:flex;flex-direction: row;justify-content: flex-start;;}
.seccionLeft{padding-top: 30px;width: 35%;display: flex;justify-content: center;flex-direction: column;align-items: center;}
.infoSeccion{display: flex;justify-content: center;flex-direction: column;align-items: flex-start;}
.seccionLeft .perfilHeaderITem{margin-bottom: 10px;}
.btnSendMessage{text-align: center;color:#fff;font-weight: bold;background-color: #FCA043;width: 50%;margin: 15px auto;padding: 10px;border-radius: 30px}
.rateContent{display:flex;flex-direction: row;align-items: flex-start;justify-content: center;}
.numberRate{font-size: 2.5rem;font-weight: 700;margin: 5px;margin-top: 0px}
.contentStart{margin-top: 8px}





ul { padding: 0; margin: 0; list-style: none; }
img { display: block; width: 100%; border: none; }
.clear { clear: both; height: 0px; }

.col2 li { float: left; width: 50%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }

.col3 li { float: left; width: 33.33%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }
.col4 li { float: left; width: 25%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }


@media screen and (min-width: 600px) and (max-width: 1000px) {
  .col3 li {width: 50%}

}

@media screen and (min-width: 1001px) and (max-width: 1439px) {
.col3 li {width: 33.33%}
}

@media (min-width: 2000px){
  .col3 li {width: 25%}

}
