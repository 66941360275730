.CardItem{position: relative;background-color: #fff;width: 90%;margin: 10px auto;cursor:pointer; display: flex;flex-direction: row;justify-content: space-around;align-items:center;border: 1px solid #ccc;height: 75px;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.CardItem:hover{border: 1px solid #d38383;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.imgCardItem{display: flex;justify-content: center;align-items: center;align-self: center;width: 60px;height: 60px;position: absolute;left: -20px;background-color:#fff;border: 1.5px solid #d38383}
.imgCardItem img{object-fit: contain;width: 50px;height: 50px;}
.infoCardItem{text-align: center;}
.infoCardItem h3{font-size: 16px;font-weight: 500;margin: 0px}
.infoCardItem p{font-size: 12px;font-weight: 400;color: #898888;}
.indicadorStatus{width: 20px;height: 20px;background-color: #ccc;position: absolute;border-radius: 50%;right: -10px;top: -10px;}
.indicadorStatus.expired{background-color: #D66E3E}
.indicadorStatus.active{background-color: #5BC162}
.messgeContent{text-align: center;font-size: 14px; font-weight: 600;}
.messgeContent p{color: #D66E3E;}
