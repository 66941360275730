
.clear { clear: both; height: 0px; }


.div-principal-envios-vendedor{
}
.div-label-buscar{
  padding-left:80%;
}
.ContentreCAPTCHA{margin: 10px auto;width: 35%;}

.ui.loader{height: 50vh}
.CancelCard{
  width: 85%;
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
}
#cardCancelacion{
  text-align: center;
  width: 380px;
  margin: 30px 10px;
}

#contentCardVenddor{
  display: flex;
}
#contentCardModalVendedor{
  display: flex;
}

#btnCancelacion{
  width: 210px;
  font-size: 12px;
}
.contentBtnCancelacion{
  margin: 0 auto;
  width: 210px;
}
#btnAceptBooking{
  width: 210px;
  font-size: 12px;
  animation: pulse 2.5s ease-in ;
}
@keyframes pulse{
  0%  {transform: scale(0.9);}
  10%  {transform: scale(1.1);}
  20%  {transform: scale(0.9);}
  30%  {transform: scale(1.1);}
  40%  {transform: scale(0.9);}
  50%  {transform: scale(1.1);}
  60%  {transform: scale(0.9);}
  70%  {transform: scale(1.1);}
  80%  {transform: scale(0.9);}
  90%  {transform: scale(1.1);}
  100%  {transform: scale(0.9);}
}

.actions{
  display: inline-flex;
  position: relative;
  float: right;
}

.div-fechas{
  padding-top: 5%;
  padding-left: 30%;
  display:inline-flex;
}

.div-modal-envios{

  display:flex;
}

.div-uploader-docs-envios{
  margin-top:10%;
  width:100%;
  height:10vh;
}

.div-stepper-envios{
  width:70%;
}
.FormInputsEmail{width: 50%;margin: 0 auto;}
.inputsMitad{display: flex;position: relative;margin: 0 auto;width: 360px;}
.FormInputsEmail textarea{display: block;margin: 30px auto;width: 340px;height: 100px;padding: 10px;}
.FormInputsEmail input{margin: 5px 10px}
.FormInputsEmail h2 {text-align: center;margin: 0px;margin-bottom: 20px;}
.btnSend{cursor: pointer;width: 90px;background-color: #c57005;color: #fff;text-align: center;padding: 5px 10px;border-radius: 5px;margin-left: auto;margin-right: 40px;margin-bottom: 40px;}
.btnSend:hover{opacity: 0.5}
.btnSend p{color: #fff;}
