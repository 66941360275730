.BuscadorRutas{height: 100vh;min-height: 750px; background-color: #f7f7f7;}
.BuscadorRutas .header-container{height: 86%;margin: .4rem;border-radius: 4vh;background-color: rgb(255, 255, 255);background:linear-gradient(rgba(244, 99, 99 , 0.81), rgba(244, 99, 99 , 0.21)), url('https://firebasestorage.googleapis.com/v0/b/exportaciones-e2444.appspot.com/o/artDesign%2Fpexels-samuel-wo%CC%88lfl-1427541%201.svg?alt=media&token=9cbd4839-d781-47f3-a1ef-7488590bc88f');background-size: cover;background-repeat: no-repeat;}
.BuscadorRutas .header-container .text{width: 77%;padding: 9rem; padding-top: 14rem;}
.BuscadorRutas .header-container .text h1{font-family: 'Poppins';font-weight: 700;color: #FFFFFF;font-size: 4.1rem;letter-spacing: -0.02rem;}
.BuscadorRutas .header-container .text p{font-family: 'Poppins';font-weight: 500;font-size: 1.1rem;color: #FFFFFF;line-height: 2rem;}
.BuscadorRutas{background-color: #f7f7f7;}

.BuscadorRutas .header-container .barra{background: #FFFFFF;box-shadow:0 0 5px hsl(0 0% 78%);height:4.8rem; border-radius:100vw;display:flex;justify-content:center;font-size:.9rem;width: 75%;margin-left: auto;margin-right: auto;}
.BuscadorRutas .header-container .barra div{border-radius:inherit;padding:0.5rem 2.5rem;transition:background 250ms ease;}
.BuscadorRutas .header-container .barra .origin,  .BuscadorRutas .header-container .barra .destination, .BuscadorRutas .header-container .barra .cargoType{width: 34%;}
.BuscadorRutas .header-container .barra input[type="text"]{background:none;border:none;padding:0.2rem 0 0 0;}
  
  input[type="text"]:focus{outline:none;}
  .guests{
    position: relative;  
  }
  .cargoType span{position:absolute;top:50%;right:20px;transform:translateY(-50%);background:#F46363;color:white;font-size:0.8rem;padding:1.5rem;border-radius:50%;}
  
  .barra>div{position:relative;}
  
  
  .barra>div::before{
    position:absolute;
    content:"";
    left:0;
    top:50%;
    transform:translateY(-50%);
    width:1px;
    height:55%;
    background:hsl(0 0% 90%);
  }
  
  .barra>div:nth-of-type(1)::before{
     background:transparent;
  }
  .barra>div:hover::before{background:transparent;}
  
.BuscadorRutas .second-contain{min-height: 30rem;}
.BuscadorRutas .second-contain .text{ width: 80%;margin-left: auto;margin-right: auto;margin-top: 10rem;}
.BuscadorRutas .second-contain .text .circle{ background: #F46363;border-radius: 50%; width: 60px; height: 60px;margin-left: auto;margin-right: auto;padding: 0rem;}
.BuscadorRutas .second-contain .text h1{font-family: 'Poppins';font-style: normal;font-weight: 700;font-size: 20px;line-height: 120%;color: #000000;text-align: center;padding-top: 3rem;}
.BuscadorRutas .second-contain .text p{font-family: 'Poppins';font-style: normal;font-weight: 500;font-size: 16px;line-height: 30px;text-align: center;color: #737476;padding-top: 2rem;}

.BuscadorRutas .second-contain .botonTriple{display: flex;background-color: #E8E8E8; width: 90%;margin-left: auto;margin-right: auto;margin-top: 6rem;border-radius: 15px;}
.BuscadorRutas .second-contain .botonTriple h3{padding: 1.5rem; margin:0;font-family: 'Poppins';font-style: normal;font-weight: 400;font-size: 1.1rem;line-height: 0%;color: #000000;width: 100%;}
.BuscadorRutas .second-contain .cards{min-height: 50rem;width: 100%; background: radial-gradient(32.51% 32.51% at 50% 50%, rgba(254, 0, 61, 0.79) 0%, rgba(253, 253, 253, 0) 100%);opacity: 0.4;}

@media screen and (min-width:1850px){
    .BuscadorRutas .header-container .text{width: 50%;padding-top: 16rem;}
    .BuscadorRutas .header-container .text h1{ font-size: 5rem;}
    .BuscadorRutas .header-container .text p{font-size: 1.4rem;width: 70%;}
    .BuscadorRutas .second-contain .text p{font-size: 20px;}
    .BuscadorRutas .second-contain .text h1{font-size: 30px;}
    .BuscadorRutas .second-contain .text{width: 60%;}

    .BuscadorRutas .second-contain .botonTriple{width: 60%;}
}

    @media screen and (min-width:1350px){
    .BuscadorRutas .header-container .text{width: 60%;padding-top: 15rem;}
    .BuscadorRutas .header-container .text h1{ font-size: 4.3rem;}
    .BuscadorRutas .header-container .text p{font-size: 1.2rem;}
    .BuscadorRutas .second-contain .text p{font-size: 18px;}
    .BuscadorRutas .second-contain .text h1{font-size: 22px;}
    .BuscadorRutas .second-contain .botonTriple{width: 70%;}

    }

    @media (max-width:990px){
        .BuscadorRutas .header-container .text{width: 94%;padding: 6rem;padding-top: 10rem;}
        .BuscadorRutas .header-container .text h1{font-size: 4.1rem;}
        .BuscadorRutas .header-container .text p{font-size: 1.2rem;}
        .BuscadorRutas .header-container .barra{width: 90%;}
        }
    @media (max-width: 575.98px) { 
        .BuscadorRutas .header-container .text{width: 100%;padding: 4rem;padding-top: 11rem;}
        .BuscadorRutas .header-container .text h1{font-size: 3rem;}
        .BuscadorRutas .header-container .text p{font-size: 1.2rem;}
     }