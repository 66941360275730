.header-perfil{
    padding-left: 90px;
    padding-right: 20px;
}

ul { padding: 0; margin: 0; list-style: none; }
.clear { clear: both; height: 0px; }
.col2 li { float: left; width: 50%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }
.col3 li { float: left; width: 33.33%; -webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease; position: relative; }

#rowBank{margin-top: 20px;text-align: center;}
.ui.form {width: 100%;}
.btnUploadContent{text-align: center; border-radius: 50%; width: 6em;height: 6em; margin: 0 auto;position: relative;}

.filepond--contenido{cursor: pointer;}

.loaderBank, .loaderBank:after {border-radius: 50%;width: 8em;height: 8em;}
.loaderBankActive,.loaderBankActive:after{border-radius: 50%;width: 8em;height: 8em;}

.loaderBankSpiner,.loaderBankSpiner:after{border-radius: 50%;width: 8em;height: 8em;}
.iconDone{position: relative;top: calc(50% - 20px);}
.loaderBank {margin: 0px auto;font-size: 10px;left: 3px;top: -4px;position: absolute;text-indent: -9999em;border-top: 3px solid rgba(0, 0, 0, 0.2);border-right: 3px solid rgba(0, 0, 0, 0.2);border-bottom: 3px solid rgba(0, 0, 0, 0.2);border-left:  3px solid rgba(0, 0, 0, 0.2);}
.loaderBankActive {display: block;margin: 0px auto;left: 3px;top: -4px;font-size: 10px;position: absolute;text-indent: -9999em;border: 3px solid #21ba45;}

.loaderBankSpiner {display: block;margin: 0px auto;left: 3px;top: -4px;font-size: 10px;position: absolute;text-indent: -9999em;border-top: 3px solid rgba(0, 0, 0, 0.2);border-right: 3px solid rgba(0, 0, 0, 0.2);border-bottom: 3px solid rgba(0, 0, 0, 0.2);border-left: 3px solid #21ba45;-webkit-transform: translateZ(0);-ms-transform: translateZ(0);transform: translateZ(0);-webkit-animation: load8 1.1s infinite linear;animation: load8 1.1s infinite linear;}


@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
select.selectCountry{-webkit-appearance: none;-moz-appearance: none;appearance: none;padding: 5px;}

img#imgPerfil {border-radius: 50%;width: 150px; height: 150px; object-fit: contain;}
#imgPerfilModal img {border-radius: 50%;width: 150px; height: 150px; object-fit: contain;}
#listaCards{padding: 5px;}

#ItemCards{display:inline-flex;}
#perfilRate{border:1px solid #D5D5D5;margin-left: 65%;text-align: center;border-radius: 0.9em;}
#perfilRateModal{border:1px solid #D5D5D5;text-align: center;border-radius: 0.9em;width: 110%;}

#listaCards :hover {opacity: .5;content: "Remove";}
#ContentCards{margin-left: 10%;}

#ModalContentBank{margin-bottom: 60px;width: 100%;}


#ModalBank{text-align: center;width: 60%;}

.formData {justify-content: center;display: flex;}
.formData .itemDobleForm{display: flex;flex-direction: row;}
.formData .itemSencillo{text-align: center;}
