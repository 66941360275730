*, *:before, *:after {box-sizing: border-box;margin: 0;padding: 0;}

body {font-family: "Open Sans", Helvetica, Arial, sans-serif;}


input, button {  border: none;  outline: none;  background: none;  font-family: "Open Sans", Helvetica, Arial, sans-serif;}
/*****************MODAL************************/
.FormRegistro {text-align: center;}
#FormInputs {margin-left: 10%;}
.FormInputsCliente{ display: flex;justify-content: center;}
.btnModalClientes{margin-bottom: 20px; display: flex;justify-content: center;}
.ContentreCAPTCHA{margin: 10px auto;width: 35%;}
#DimmerRegistro { z-index: 1000;}
#ModalRegistro{z-index: 2;}
.cont {overflow: hidden;position: relative;width: 900px;height: 600px;margin: 0 auto 100px;background: #fff;}
.logoLogin{width: 30%;margin: 0px auto;}
.form {position: relative;width: 640px;height: 100%;transition: transform 1.2s ease-in-out;padding: 50px 30px 0;justify-content: center;}

.sub-cont {overflow: hidden;position: absolute;left: 640px;top: 0;width: 900px;height: 100%;padding-left: 260px;background: #fff;transition: transform 1.2s ease-in-out;}
.cont.s--signup .sub-cont {transform: translate3d(-640px, 0, 0);}

button {display: block;margin: 0 auto;width: 260px;height: 36px;border-radius: 30px;color: #fff;font-size: 15px;cursor: pointer;}
.img {overflow: hidden;z-index: 2;position: absolute;left: 0;top: 0;width: 260px;height: 100%;padding-top: 360px;}
.img:before {content: "";position: absolute;right: 0;top: 0;width: 900px;height: 100%;background-image: url("./imgs/login-img.jpg");background-size: cover;transition: transform 1.2s ease-in-out;}
.img:after {content: "";position: absolute;left: 0;top: 0;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.6);}
.cont.s--signup .img:before {transform: translate3d(640px, 0, 0);}
.img__text {z-index: 2;position: absolute;left: 0;top: 50px;width: 100%;padding: 0 20px;text-align: center;color: #fff;transition: transform 1.2s ease-in-out;}
.img__text h2 {margin-bottom: 10px;font-weight: normal;}
.img__text p {font-size: 14px;line-height: 1.5;}
.cont.s--signup .img__text.m--up {transform: translateX(520px);}
.img__text.m--in {transform: translateX(-520px);}
.cont.s--signup .img__text.m--in {transform: translateX(0);}
.img__btn {overflow: hidden;z-index: 2;position: relative;width: 100px;height: 36px;margin: 0 auto;background: transparent;color: #fff;text-transform: uppercase;font-size: 15px;cursor: pointer;}
.img__btn:after {content: "";z-index: 2;position: absolute;left: 0;top: 0;width: 100%;height: 100%;border: 2px solid #fff;border-radius: 30px;}
.img__btn span {position: absolute;left: 0;top: 0;display: flex;justify-content: center;align-items: center;width: 100%;height: 100%;transition: transform 1.2s;}
.img__btn span.m--in {transform: translateY(-72px);}
.cont.s--signup .img__btn span.m--in {transform: translateY(0);}
.cont.s--signup .img__btn span.m--up {transform: translateY(72px);}

h2 {width: 100%;font-size: 26px;text-align: center;}

label {display: block;width: 260px;margin: 25px auto 0;text-align: center;}
label span {font-size: 12px;color: #cfcfcf;text-transform: uppercase;}

input {display: block;width: 100%;margin-top: 5px;padding-bottom: 5px;font-size: 16px;border-bottom: 1px solid rgba(0, 0, 0, 0.4);text-align: center;}

.forgot-pass {margin-top: 15px;text-align: center;font-size: 12px;color: #cfcfcf;}

.submit {margin-top: 40px;margin-bottom: 20px;background: #d4af7a;text-transform: uppercase;}
.submit2 {margin-top: 40px;margin-bottom: 20px;background: #d4af7a;text-transform: uppercase;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.submit2:hover {transform: scale(1.1); transform-origin: center;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}

.fb-btn {border: 2px solid #d3dae9;color: #8fa1c7;}
.fb-btn span {font-weight: bold;color: #455a81;}
select.selectCountry{-webkit-appearance: none;-moz-appearance: none;appearance: none;padding: 5px;}

.sign-in {transition-timing-function: ease-out;justify-content:center;text-align: center;}
.cont.s--signup .sign-in {transition-timing-function: ease-in-out;transition-duration: 1.2s;transform: translate3d(640px, 0, 0);}

.sign-up {transform: translate3d(-900px, 0, 0);}
.cont.s--signup .sign-up {transform: translate3d(0, 0, 0);}

.icon-link {position: absolute;left: 5px;bottom: 5px;width: 32px;}
.icon-link img {width: 100%;vertical-align: top;}
.icon-link--twitter {left: auto;right: 5px;}

.contentTerminos{display:flex;flex-direction: column;align-items: center;margin-top: 30px;}
.itemTerminos{display:flex;flex-direction: row;margin-top: 10px;}
.itemTerminos p {display: flex;flex-direction: row;}
