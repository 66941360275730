#CardDetallesCard{
	width: 100%;
	position: sticky;
top: 0px;
}

#inputIndividual{
	margin: 10px;
	margin-left: 30px;
}
.floatLabel{background-color: red;padding: 5px 8px;text-align: center;border-radius: 30px;font-size: 12px;font-weight: 900;color: #fff;}
.IconoRightDetalles{float: right; text-align: center;}
.btnPagar{text-align: center;}
.Textmensaje{width: 40%;border: 1px solid #ccc;border-radius: 5px;padding: 5px;}
.enviarRechazo{cursor: pointer;margin: 20px auto;width: 20%;background-color: #000;color: #fff;padding: 5px;font-size: 20px;border-radius: 10px;}
.enviarRechazo:hover{opacity: 0.5}
.headerBanner{position: absolute;top: 50%;left: 50%;width: 100%;z-index: 10;text-align: center;display: flex;justify-content: center;align-items: center;color:#fff;background: rgba(0, 0, 0, 0.5);transform: translate(-50%,-50%);transform-origin: center;}
.headerBanner i {margin-right: 20px;}
