#ModalPayHeader{
  text-align: center;
}
#ModalPayP{
  margin-left: 16%;
}
#ModalPayList{
  margin-left: 10%;
}
#ModalPay{
  width:40%;
}
#iconDownloadFoto{
  cursor: pointer;
}
#iconDownloadFoto:hover{opacity: 0.5}
#ModalPayButtonCancel{
  width: 30%;
}
#ModalPayButtonAcept{
  width: 30%;
}

#ItemCards{
  width: 90%;
}



#ItemCards:active{
  background-color: #eaf9ed;
}
.ui.loader{height: 50vh}

.ui.header+p {
    margin: 30px;
    text-align: center;
}

.ui.modal>.actions {
  width: 100%;
  justify-content: flex-end;
}

.StatusEnvioUsuario{
  width: 60%;
  margin: 0 auto;
}
.StatusTags{
  display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;
    padding: 20px;
}
.itemStatusUsuario{width: 80px;height: 80px;padding: 20px;background-color: #dcdcdc;border-radius: 100px;margin: 10px;text-align: center;}
.itemStatusUsuario p{line-height: 17px; font-size: 12px;color:#4a4a4a;}
.itemStatusUsuario.activo{background-color: #e69b2b}
.itemStatusUsuario.activo p{color: #fff}
.div-principal-envios-usuario{

}
.paticulasContent {position: relative;height: 210px}
.paticulasContent .contentTitulo{position: relative;text-align: center;}
.paticulasContent .contentTitulo .titulo{display: flex;color: #fff;justify-content: center;padding-top: 10px;}
.paticulasContent .contentTitulo .titulo h3{font-size: 30px;margin: 0px 10px;}

.paticulasContent .contentBuscador{position: relative;margin: 0px auto;width: 40%;margin-top: 20px;}
.paticulasContent .contentBuscador .Buscador{display: flex;}
.paticulasContent .contentBuscador .Buscador input{height: 30px;margin: 0px 10px;background-color: #fff;font-size: 15px;font-family: 'Roboto';border-radius: 5px;padding: 5px;color: #6f6f6f;font-weight: 500}
.btnBuscarFecha {cursor: pointer;margin:0px 15px;background-color: #e69b2b;width: 250px;color: #fff;text-align: center;font-weight: 600;padding: 5px;border-radius: 5px;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.btnBuscarFecha:hover{background-color: #f9c981;-webkit-transition: all .5s ease; -moz-transition: all .5s ease; -o-transition: all .5s ease; transition: all .5s ease;}
.paticulasContent .buscadorPorID{margin-top: 30px;}
.paticulasContent .buscadorPorID input{height: 30px;margin: 0px auto;background-color: #fff;font-size: 15px;font-family: 'Roboto';border-radius: 5px;padding: 5px; width: 100%;color: #6f6f6f;font-weight: 500}
.onlyDolars{font-size: 20px}
.div-modal-envios{
margin-left: 70vh;
  display:flex;
}
.labelMini{
  font-size: 0.8em;
  color: gray;
}

.div-uploader-docs-envios{
  margin-top:10%;
  width:60%;
  height:10vh;
}

.div-stepper-envios{
  width:70%;
}

#contentCard{
  display: flex;
}
#contentCardModal{
  display: flex;
}
.TransaccionID{background-color: #c57007;padding: 10px;font-size: 15px;}
.TransaccionID.red{background-color: #E37D7D;padding: 10px;font-size: 15px;}
.TransaccionID p {color: #fff}
.contentImgEnvio{width: 200px;font-size: 75px;text-align: center;padding: 40px;}
.contentImgEnvio img{width: 100%}
.InfoContent .TitleEnvio{ font-size: 23px;  margin: 5px 0px 10px 0px;}
.InfoContent .TitleEnvio span{ margin: 0px 5px; text-transform: lowercase;; display: inline-block;}
.InfoContent .TitleEnvio span::first-letter{ text-transform: uppercase;}
.InfoContent .descriptionContent .ContenidoEnvio{font-size: 16px;margin: 5px 0px;}
.InfoContent .descriptionContent .CargoIMO{ font-size: 12;}
.InfoContent .descriptionContent .ContentPriceEnvio{font-size: 25px;margin: 30px 0px; }
.InfoContent .descriptionContent .ContentPriceEnvioModal{font-size: 25px;margin: 15px 0px; }
.InfoContent .descriptionContent .PagoEnvio{ background-color: #d89128;padding: 10px;color: #fff;border-radius: 10px;}
.InfoContent .descriptionContent .PagoEnvioModal{ padding: 10px;color: #d89128;border-radius: 10px;}
.InfoContent .descriptionContent .TipoPagoEnvio{    background-color: #e46767;color: #fff;padding: 10px;border-radius: 10px;line-height: 9px;;font-size: 12px; margin-left: 20px;}
.InfoContent .descriptionContent .MedidasEnvio{  margin: 10px 0px;}
.InfoContent .descriptionContent .CantidadEnvio{    font-size: 17px;}
.InfoContent .descriptionContent .PesoEnvio{     font-size: 17px;margin-left: 20px;}
.InfoContent .descriptionContent .ContenidoPeso{   font-size: 14px;margin-left: 10px;color: #464444;}
.StatusContentEnvio{right: 40px;position: absolute;}
.CancelarcionMsj{margin-bottom: 10px;margin-left: 15px;}
